.fw-100 {
  font-weight: 100 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-21 {
  font-size: 21px;
}

.lh-08 {
  line-height: 0.8 !important;
}

.lh-10 {
  line-height: 1 !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

.lh-15 {
  line-height: 1.5 !important;
}

.lh-18 {
  line-height: 1.8 !important;
}

.lh-20 {
  line-height: 2 !important;
}

.letter-sm {
  letter-spacing: 0.16em !important;
}

.letter-md {
  letter-spacing: 0.24em !important;
}

.letter-lg {
  letter-spacing: 0.38em !important;
}

span.headline {
  border-bottom: 3px solid;
}

/* List Style */
ul,
ol,
dl {
  list-style: none outside none;
  padding: 0;
  margin: 0;
  list-style-position: outside;
}

dd,
dt {
  margin: 0;
  padding: 0;
}

/* --- basic --- */
.list-basic {
  margin-left: 25px;
  counter-reset: ol-counter;
}

.list-basic li {
  position: relative;
  padding-bottom: 15px;
  line-height: 1.45;
}

.list-basic li:before {
  width: 16px;
  height: 16px;
  display: block;
  right: 100%;
  margin-right: 13px;
  position: absolute;
  text-align: right;
}

ol.list-basic li:before {
  content: counter(ol-counter, decimal);
  counter-increment: ol-counter;
  margin-right: 13px;
  font-weight: 600;
  top: 5px;
  font-size: 15px;
}

ul.list-basic li:before {
  width: 8px;
  height: 2px;
  content: '';
  background-color: #ccc;
  top: 13px;
}

ol.list-basic {
  margin-left: 50px;
}

ol.list-basic.list-bg li:before {
  background-color: #111;
  color: #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  margin-right: 22px;
}

/* Hr */
hr {
  margin: 27px 0px 27px 0px;
  border: 0;
  border-top-width: 1px;
  border-style: solid;
}

hr.separator-no-padding {
  margin-top: 0px;
  margin-bottom: 0px;
}

hr.separator-double-padding {
  margin: 54px 0px 54px 0px;
}

hr.no-top-margin {
  margin-top: 0px;
}

hr.double-top-margin {
  margin-top: 72px;
}

hr.dotted {
  border-style: dotted;
}

hr.dashed {
  border-style: dashed;
}

hr.separator-break {
  margin-left: auto;
  margin-right: auto;
}

hr.separator-break {
  width: 90px;
  border-top-width: 2px;
  border-color: rgba(0, 0, 0, 0.2);
}

hr.separator-break.separator-accent {
  border-color: #006cff !important;
}

/* ===========================================================================
          Hero Background
*/
.hero-wrap {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hero-caption {
  position: relative;
  display: table;
  height: 100%;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  z-index: 2;
}

.hero-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.hero-text.align-end {
  vertical-align: bottom;
}

.hero-text.align-start {
  vertical-align: top;
}

.hero-text.text-left {
  text-align: left;
}

.hero-text.text-right {
  text-align: right;
}

.hero-wrap h1,
.hero-wrap h2,
.hero-wrap h3,
.hero-wrap h4,
.hero-wrap h5,
.hero-wrap h6 {
  line-height: 1.2;
  margin: 0;
}

.img-bg-center {
  background-position: center !important;
  background-size: cover;
}

.img-bg-top {
  background-position: top !important;
  background-size: cover;
}

.img-bg-bottom {
  background-position: bottom !important;
  background-size: cover;
}

.img-bg-soft,
.img-bg-softer {
  background-position: center;
  position: relative;
}

.img-bg-soft:before,
.img-bg-softer:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .hero-caption {
    width: 90%;
  }
}

.half-bg {
  position: relative;
}

.half-bg-right,
.half-bg-left {
  position: absolute;
  top: 0;
  bottom: 0;
  /*width:50%;*/
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.half-bg-right {
  right: 0;
}

.half-bg-left {
  left: 0;
}

.half-bg-right img,
.half-bg-left img {
  display: none;
}

@media (max-width: 991px) {
  .half-bg-right,
  .half-bg-left {
    position: relative;
    width: 100%;
    background-image: none !important;
  }

  .half-bg-right img,
  .half-bg-left img {
    display: block;
    margin-bottom: 50px;
  }
}

/* ===========================================================================
  Overlay
*/
.sub-overlay {
  position: relative;
}

.sub-overlay:before {
  content: ' ';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.sub-overlay *:not(.container):not(.container-fluid):not(.imgBg-holder) {
  z-index: 2;
}

.dark-overlay,
.light-overlay {
  position: relative;
}

.dark-overlay::after,
.light-overlay::after {
  content: ' ';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.overlay-content {
  position: relative;
  z-index: 20;
}

.overlay-link {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 30;
}

/* ---- Overlay ----*/
[data-overlay] {
  position: relative;
}

[data-overlay]:before {
  position: absolute;
  content: '';
  background: #141618;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

[data-overlay] *:not(.container):not(.container-fluid):not(.imgBg-holder) {
  z-index: 2;
}

[data-overlay].light-bg:before {
  background: #fff;
}

[data-overlay].accent-bg:before {
  background: #ff0033;
}

[data-overlay='1']:before {
  opacity: 0.1;
}

[data-overlay='2']:before {
  opacity: 0.2;
}

[data-overlay='3']:before {
  opacity: 0.3;
}

[data-overlay='4']:before {
  opacity: 0.4;
}

[data-overlay='5']:before {
  opacity: 0.5;
}

[data-overlay='6']:before {
  opacity: 0.6;
}

[data-overlay='7']:before {
  opacity: 0.7;
}

[data-overlay='8']:before {
  opacity: 0.8;
}

[data-overlay='9']:before {
  opacity: 0.9;
}

[data-overlay='10']:before {
  opacity: 1;
}

[data-overlay='0']:before {
  opacity: 0;
}

/* ===========================================================================
      Img Background
*/
.imgBg {
  position: relative;
}

.imgBg .container {
  z-index: 2;
}

.imgBg-holder {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center !important;
  background-repeat: no-repeat;
  z-index: 0;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}

.imgBg-holder:not([class*='col-']) {
  width: 100%;
}

.imgBg-holder.imgBg-top {
  background-position: 50% 0% !important;
}

.imgBg-holder.imgBg-bottom {
  background-position: 50% 100% !important;
}

/* .imgBg-holder img {
  display: none !important;
} */

/* ---- Image Block ----*/
.imgBlock {
  position: relative;
  padding: 0;
}

.imgBlock > .container,
.imgBlock > div[class*='col-']:not(.imgBlock-left):not(.imgBlock-right) {
  padding-top: 150px;
  /*150px*/
  padding-bottom: 150px;
  float: none;
  overflow: hidden;
}

.imgBlock.imgBlock-sm > .container,
.imgBlock.imgBlock-sm
  > div[class*='col-']:not(.imgBlock-left):not(.imgBlock-right) {
  padding-top: 100px;
  /*100px*/
  padding-bottom: 100px;
  float: none;
  overflow: hidden;
}

.imgBlock .imgBlock-left,
.imgBlock .imgBlock-right {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 2;
}

.imgBlock .imgBlock-left {
  left: 0;
}

.imgBlock .imgBlock-right {
  right: 0;
}

.imgBlock .txt-right {
  padding-left: 50px;
}

.imgBlock .txt-left {
  padding-right: 50px;
}

@media all and (max-width: 991px) {
  .imgBlock .imgBlock-left,
  .imgBlock .imgBlock-right {
    position: relative;
    min-height: 450px;
  }

  .imgBlock > .container,
  .imgBlock > div[class*='col-']:not(.imgBlock-left):not(.imgBlock-right),
  .imgBlock.imgBlock-sm > .container,
  .imgBlock.imgBlock-sm
    > div[class*='col-']:not(.imgBlock-left):not(.imgBlock-right) {
    padding-top: 60px;
    /*60px*/
    padding-bottom: 60px;
  }

  .imgBlock .txt-right {
    padding-left: 0px;
  }

  .imgBlock .txt-left {
    padding-right: 0px;
  }
}

@media all and (max-width: 767px) {
  .imgBlock .imgBlock-left,
  .imgBlock .imgBlock-right {
    min-height: 360px;
  }
}

/* ---- Parallax ----*/
.parallax {
  overflow: hidden;
}

.parallax .imgBg-holder {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
}

.parallax > .imgBg-holder {
  height: 100vh;
  top: -50vh;
  transition: opacity 0.3s ease !important;
  -webkit-transition: opacity 0.3s ease !important;
  -webkit-transform-style: preserve-3d;
}

.parallax:first-child .imgBg-holder {
  top: 0;
}

@media all and (max-width: 1024px) {
  .parallax > .imgBg-holder {
    top: 0 !important;
    transform: none !important;
    -webkit-transform: none !important;
    height: 100%;
  }
}

.parallax {
  will-change: contents;
}

/* ===========================================================================
      Box Icon
*/
.box-icon {
  margin: 0;
}

.box-icon > a,
.box-icon .box-icon-title {
  text-decoration: none !important;
  /*display:block;*/
}

.box-icon .box-icon-title > i,
.box-icon > i {
  font-size: 40px;
  margin-bottom: 20px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.box-icon > p {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .box-icon.box-icon-center > p {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.box-icon .box-icon-title > h3 {
  /*font-size:20px;*/
  line-height: 35px;
  margin-bottom: 15px;
  letter-spacing: -0.03em;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.box-icon .box-icon-title > img + h2,
.box-icon .box-icon-title > img + h3,
.box-icon .box-icon-title > img + h4,
.box-icon .box-icon-title > img + h5 {
  font-size: 16px;
  line-height: 16px;
}

.box-icon a.box-icon-more {
  display: block;
  text-align: right;
  position: relative;
  color: #000;
}

.box-icon a.box-icon-more:after {
  font-family: FontAwesome;
  content: '\f105';
  padding-left: 10px;
  top: 50%;
  margin-top: -2px;
}

.box-icon.box-icon-left .box-icon-title > i {
  float: left;
}

.box-icon.box-icon-right .box-icon-title > i {
  float: right;
  margin-right: 0;
  margin-left: 10px;
}

.box-icon.box-icon-right {
  text-align: right;
}

@media only screen and (max-width: 991px) {
  .box-icon.box-icon-right .box-icon-title > i {
    float: left;
    margin-right: 10px;
    margin-left: 0;
  }

  .box-icon.box-icon-right {
    text-align: left;
  }
}

/* box content */
.box-icon.box-icon-content {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 45px 15px 15px 15px;
  display: block;
  margin-top: 33px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

section.dark .box-icon.box-icon-content {
  background-color: rgba(0, 0, 0, 0.1);
}

.box-icon.box-icon-content .box-icon-title > i {
  background-color: #fff;
  top: 0;
  margin-left: -33px;
  position: absolute;
}

section.alternate .box-icon.box-icon-content .box-icon-title > i {
  background-color: #f9f9f9;
}

/* round */
.box-icon.box-round .box-icon-title > i,
.box-icon.box-round > i {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

/* solid */
.box-icon.box-icon-solid .box-icon-title > i,
.box-icon.box-icon-solid > i {
  border: #ccc 1px solid;
  background-color: transparent;
  color: inherit;
}

/* background */
.box-icon.box-icon-bg .box-icon-title > i,
.box-icon.box-icon-bg > i {
  background-color: rgba(0, 0, 0, 0.08);
  color: inherit;
}

/* side */
.box-icon.box-icon-side {
  position: relative;
  padding-left: 60px;
  margin-top: 40px;
}

.box-icon.box-icon-side > span,
.box-icon.box-icon-side > i {
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 44px;
  width: 50px;
  text-align: center;
  line-height: 1;
}

.box-icon.box-icon-side > span {
  letter-spacing: -0.2rem;
}

/* side-biz */
.box-icon.box-icon-side-biz {
  position: relative;
  padding-left: 160px;
}

.box-icon.box-icon-side-biz > span,
.box-icon.box-icon-side-biz > i {
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 44px;
  width: 50px;
  text-align: center;
  line-height: 1;
}

.box-icon.box-icon-side-biz > span {
  letter-spacing: -0.2rem;
}

@media (max-width: 575px) {
  .box-icon.box-icon-side {
    padding-left: 60px;
  }

  .box-icon.box-icon-side > span,
  .box-icon.box-icon-side > i {
    font-size: 34px;
  }
}

/* center */
.box-icon.box-icon-center .box-icon-more,
.box-icon.box-icon-center .box-icon-title,
.box-icon.box-icon-center > p {
  text-align: center !important;
}

.box-icon.box-icon-center .box-icon-title > i {
  float: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* Round bg */
.box-icon .box-icon-title.box-icon-bg > i {
  color: #fff;
  background-color: #232323;
}

/* Round Bg Trensparent */
.box-icon.box-icon-solid .box-icon-title.box-icon-bg > i {
  color: inherit;
  background-color: transparent;
}

/* ---- size ----- */
.box-icon.box-icon-lg .box-icon-title > i,
.box-icon.box-icon-lg > i {
  width: 170px;
  height: 170px;
  line-height: 170px;
  font-size: 70px;
}

.box-icon.box-icon-md .box-icon-title > i,
.box-icon.box-icon-md > i {
  width: 140px;
  height: 140px;
  line-height: 140px;
  font-size: 50px;
}

.box-icon.box-icon-sm .box-icon-title > i,
.box-icon.box-icon-sm > i {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 40px;
}

@media (max-width: 575px) {
  .box-icon.box-icon-lg .box-icon-title > i,
  .box-icon.box-icon-lg > i {
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 60px;
  }

  .box-icon.box-icon-md .box-icon-title > i,
  .box-icon.box-icon-md > i {
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 40px;
  }

  .box-icon.box-icon-sm .box-icon-title > i,
  .box-icon.box-icon-sm > i {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 30px;
  }

  /* .box-icon.box-icon-sm .box-icon-title>i,.box-icon.box-icon-sm >i {width: 40px;height: 40px;line-height:40px;font-size: 20px;} */
  .box-icon-lg,
  .box-icon-md,
  .box-icon-sm {
    margin-bottom: 40px;
  }
}

/* ===========================================================================
        Owl Carousel
*/

.owl-carousel .item img {
  display: block;
  width: 100%;
  height: auto;
}

/* Dots */
.owl-theme .owl-dots .owl-dot span {
  background-color: #000;
  opacity: 0.2;
  width: 25px;
  height: 8px;
  margin: 5px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #000;
  opacity: 1;
}

.owl-theme.owl-dots-inner .owl-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.owl-theme.owl-dots-inner.owl-dots-right .owl-dots {
  left: auto;
  right: -30px;
}

.owl-theme.owl-dots-inner.owl-dots-left .owl-dots {
  left: 0;
  right: auto;
}

/* Arrows */
.owl-theme.owl-arrows .owl-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 15px;
  right: 15px;
}

.owl-theme.owl-arrows .owl-nav [class*='owl-'] {
  opacity: 1;
  background: transparent;
  color: #fff;
  padding: 0;
  border-radius: 0;
  line-height: 70px;
  font-size: 80px;
  font-weight: 100;
  cursor: hand;
}

.owl-theme.owl-arrows .owl-nav [class*='owl-']:hover {
  opacity: 0.5;
  text-decoration: none;
}

.owl-theme.owl-arrows .owl-nav .owl-prev {
  float: left;
}

.owl-theme.owl-arrows .owl-nav .owl-next {
  float: right;
}

.owl-theme.owl-arrows .owl-nav .arrow-left,
.owl-theme.owl-arrows .owl-nav .arrow-right {
  height: 70px;
  width: 70px;
}

.owl-theme.owl-arrows .owl-nav .arrow-left.arrow-sm,
.owl-theme.owl-arrows .owl-nav .arrow-right.arrow-sm {
  height: 50px;
  width: 50px;
}

.owl-theme.owl-arrows .owl-nav .arrow-left {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 129 129'><path d='m88.6,121.3c0.8,0.8 1.8,1.2 2.9,1.2s2.1-0.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-54,53.9c-1.6,1.6-1.6,4.2 0,5.8l54,53.9z' fill='#f4f2e6'/></svg>")
    no-repeat;
}

.owl-theme.owl-arrows .owl-nav .arrow-right {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 129 129'><path d='m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z' fill='#f4f2e6'/></svg>")
    no-repeat;
}

/* arrow centered */
.owl-arrows-center .owl-nav {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: 0;
}

.owl-arrows-center .owl-nav [class*='owl-'] {
  opacity: 1;
  background: #29cc5f;
  color: #fff;
  padding: 20px;
  margin: 0;
  border-radius: 0;
  line-height: 70px;
  font-size: 60px;
  font-weight: 100;
  cursor: hand;
}

.owl-arrows-center .owl-nav .owl-next {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.owl-arrows-center .owl-nav [class*='owl-']:hover {
  background-color: #1d9544;
  text-decoration: none;
  opacity: 1;
}

@media (max-width: 991px) {
  .owl-arrows-center {
    position: relative;
  }
}

/* Thumb item */
.owl-thumb-item img {
  width: 100px;
  height: auto;
}

.owl-thumbs {
  /*
         position: absolute;
         bottom: 0;
         left: 0;
 	*/
  display: table;
  width: auto;
  text-align: center;
  padding: 2%;
  margin: 0 auto;
}

.owl-thumb-item {
  display: table-cell;
  border: none;
  background: none;
  padding: 0;
  opacity: 0.3;
  padding: 0 2px;
}

.owl-thumb-item.active {
  opacity: 1;
}

.label {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #0a6cff;
  color: white;
  padding: 10px 20px;
  z-index: 5;
  text-align: center;
}

@media (max-width: 767px) {
  .owl-thumb-item img {
    width: 65px;
  }

  .owl-theme.owl-arrows .owl-nav .arrow-left,
  .owl-theme.owl-arrows .owl-nav .arrow-right {
    height: 50px;
    width: 50px;
  }
}

/* ===========================================================================
        inner Padding
*/

.inner-lg {
  padding: 80px !important;
}

.inner-md {
  padding: 50px !important;
}

.inner-sm {
  padding: 30px !important;
}

@media (max-width: 1200px) {
  .inner-lg {
    padding: 40px !important;
  }

  .inner-md {
    padding: 30px !important;
  }

  .inner-sm {
    padding: 5px !important;
  }
}

@media (max-width: 767px) {
  .inner-lg {
    padding: 50px 20px !important;
  }

  .inner-md {
    padding: 15px !important;
  }

  .inner-sm {
    padding: 5px !important;
  }
}

/* ===========================================================================
      Data table
*/

.data_table_list_outer {
  padding: 100px 0;
}

.data_table_list {
  display: table;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  /* border-top: 2px solid #222; */
}

.data_table_list > li {
  display: table-row;
}

.data_table_list > li > b,
.data_table_list > li > span {
  display: table-cell;
  padding: 13px 18px 13px 0;
  border-bottom: 1px solid #ddd;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.data_table_list > li > b {
  width: 140px;
  /* padding-left: 40px; */
  font-size: 16px;
  font-weight: 600;
  color: #222;
}

.data_table_list > li > span {
  padding-left: 23px;
  font-size: 16px;
  color: #666;
}

@media (max-width: 1023px) {
  .data_table_list_outer {
    padding: 7%;
  }

  .data_table_list {
    max-width: inherit;
  }

  .data_table_list > li > b {
    width: 155px;
    padding-left: 30px;
    font-size: 15px;
  }

  .data_table_list > li > span {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .data_table_list_outer {
    padding: 8% 5%;
  }

  .data_table_list {
    display: block;
  }

  .data_table_list > li {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }

  .data_table_list > li > b,
  .data_table_list > li > span {
    display: block;
    padding: 0;
    border-bottom: none;
  }

  .data_table_list > li > b {
    width: auto;
    padding-left: 0;
    font-size: 14px;
    line-height: 1.4;
  }

  .data_table_list > li > span {
    padding-left: 0;
    padding-top: 3px;
    font-size: 14px;
    line-height: 1.4;
  }

  .data_table_list > li > span br {
    display: none;
  }

  .table_list_blankspace,
  .table_list_small_blankspace {
    display: none;
  }
}

@media (max-width: 480px) {
  .data_table_list_outer.wrap {
    margin: 0;
  }

  .data_table_list_outer {
    padding: 30px 15px;
  }
}

/* ===========================================================================
        History
*/

.about_history_list {
  margin-top: 20px;
}

.about_history_list > li {
  float: left;
  width: 100%;
  padding: 53px 0;
  border-bottom: 1px solid #ddd;
}

.history_list_year {
  float: left;
  width: 25.6%;
  margin-top: -4px;
  padding-right: 15px;
  font-size: 40px;
  line-height: 1.25;
  font-weight: 500;
  color: #1694ff;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.history_list_content {
  float: left;
  width: 74.4%;
}

.history_list_content > li {
  margin-bottom: 12px;
  position: relative;
  font-size: 16px;
  line-height: 1.75;
}

.history_list_content > li:last-child {
  margin-bottom: 0;
}

.about_history_list time {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600;
  color: #222;
}

.about_history_list p {
  padding-left: 77px;
  color: #666;
}

@media (max-width: 1023px) {
  .about_history_list {
    margin-top: 15px;
  }

  .history_list_year {
    font-size: 30px;
  }

  .history_list_content > li {
    font-size: 15px;
  }

  .about_history_list p {
    padding-left: 70px;
  }
}

@media (max-width: 767px) {
  .about_history_list > li {
    padding: 30px 0;
  }

  .history_list_year {
    margin-top: 0;
    font-size: 25px;
  }

  .history_list_year > span {
    display: inline-block;
    vertical-align: top;
  }

  .history_list_content > li {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 1.6;
  }

  .about_history_list p {
    padding-left: 65px;
  }
}

@media (max-width: 480px) {
}

/* ===========================================================================
          Divider Boxs
  */

.overflow-hidden {
  overflow: hidden;
}

.divider-box li {
  padding: 50px;
  position: relative;
  float: left;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 1;
}

.divider-box.column-2 li {
  width: 50%;
  /* height: 37vmin;  */
}

.divider-box.column-3 li {
  width: 33.3333%;
}

.divider-box.column-4 li {
  width: 25%;
}

.divider-box.column-5 li {
  width: 50%;
  height: 33vmin;
}

.divider-box li:hover {
  /* opacity:1;
	cursor:pointer;
    background-color:#eee; */
}

.divider-box li:before {
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: -1px;
  border-left: 1px solid #ddd;
}

.divider-box li:after {
  width: 100%;
  height: 0;
  content: '';
  position: absolute;
  top: auto;
  left: 0;
  bottom: -1px;
  border-bottom: 1px solid #ddd;
}

.divider-box p {
  margin-bottom: 0;
}

.divider-box li {
  padding: 25px;
}

.box-icon-title-biz {
  /* padding-left: 200px; */
}

@media (max-width: 991px) {
  .divider-box li {
    padding: 15px 15px;
  }

  .divider-box.column-2 li,
  .divider-box.column-3 li,
  .divider-box.column-4 li {
    width: 100%;
  }
}

/* ===========================================================================
        Partners
*/

.partner1 .partner-box [class*='col-']:hover img,
.partner2 .partner-box [class*='col-']:hover img {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.4;
}

/* partner 01  */
.partner1 .partner-box {
  margin: 0px;
  border: 1px solid rgba(120, 130, 140, 0.13);
}

.partner1 .partner-box [class*='col-'] {
  min-height: 130px;
  border: 1px solid rgba(120, 130, 140, 0.13);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* partner 02  */
.partner2 .partner-box {
  margin: 0px;
}

.partner2 .partner-box [class*='col-'] {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  border-right: 1px solid rgba(120, 130, 140, 0.13);
  min-height: 130px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 575px) {
  .partner2 .partner-box div:nth-child(1n + 9) {
    border-bottom: none;
  }

  .partner2 .partner-box div:nth-child(4n) {
    border-right: none;
  }
}

@media (max-width: 574px) {
  .partner2 .partner-box div:nth-child(1n + 11) {
    border-bottom: none;
  }

  .partner2 .partner-box div:nth-child(2n) {
    border-right: none;
  }

  .divider-box.column-5 li {
    width: 100%;
    height: 90vmin;
  }
}

/* 07/19 개발자 박민성 공용클래스 */

.intStrongText {
  font-weight: 600;
}

.intSpan {
  color: #4e4e4e;
  font-weight: 600;
  font-size: 1rem;
  word-break: keep-all;
  padding-bottom: 0.5rem;
}
.intSpanLight {
  color: #999;
  font-weight: 600;
  word-break: keep-all;
}
.intSpanWhite {
  color: #fff;
  font-weight: 500;
  word-break: keep-all;
  text-shadow: 1px 1px 4px #939393;
}
.intText {
  color: #333;
  font-weight: 500;
  word-break: break-all;
}
.intTextBold {
  color: #484848;
  font-weight: 700;
}
.intBoldWhite {
  color: #fff;
  font-weight: 700;
}
.intColorSpan {
  color: #2349a7;
  font-weight: 700;
}
.intBigText {
  margin-top: 3rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #333;
}
.smallMarginTop {
  margin-top: 1rem;
}
.smallWidthText {
  width: 70%;
}
.middleWidthText {
  width: 85%;
}

/* 스와이퍼 영역 */
.visualTitle {
  font-size: 3rem;
}
.visualSubTitle {
  font-size: 1.5rem;
}
